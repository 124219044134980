<template>
  <div>
    <Calendar
      class="mc-calendar card"
      :attributes="attributes"
      :first-day-of-week="1"
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full overflow-hidden">
          <div class="text-sm text-right text-gray-500">
            {{ day.day }}
          </div>
          <div class="flex flex-col overflow-x-auto overflow-y-auto">
            <calendar-item
              v-for="attr in attributes"
              :key="attr.key"
              :item="attr"
            />
          </div>
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script lang="ts">
import { defineComponent, readonly } from 'vue'
import { Calendar } from 'v-calendar';
import CalendarItem from './CalendarItem.vue';

export default defineComponent({
  components: {
    Calendar,
    CalendarItem
  },

  props: {
    attributes: {
      type: Array,
      default: () => ([])
    },
  },

  setup () {
    const masks = readonly({
      weekdays: 'WWW',
    })
    
    return {
      masks
    }
  }
})
</script>

<style>
@layer components {
  .mc-calendar.vc-container {
    @apply w-full;

    & .vc-day.weekday-1 {
      @apply bg-gray-100;
    }

    & .vc-day:not(.on-bottom) {
      @apply border-b;
    }

    & .vc-day:not(.on-bottom).weekday-1 {
      @apply border-b;
    }
    
    & .vc-day:not(.on-right) {
      @apply border-r;
    }

    & .vc-header {
      @apply py-3;
    }
    
    & .vc-weeks {
      @apply p-0;
    }
    
    & .vc-weekday {
      @apply border-t border-b border-gray-200 py-2 px-0 bg-gray-50;
    }
    
    & .vc-day {
      @apply pt-0 pb-1 px-1 text-left h-32 min-w-full bg-white;
    }
    
    & .vc-day-dots {
      @apply mb-1;
    }
  }
}
</style>
