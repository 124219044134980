<template>
  <div>
    <Calendar
      class="mc-calendar card"
      :attributes="attributes"
      :first-day-of-week="1"
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full overflow-hidden">
          <div class="text-sm text-right text-gray-500">
            {{ day.day }}
          </div>
          <div class="flex flex-col overflow-x-auto overflow-y-auto">
            <calendar-item
              v-for="attr in attributes"
              :key="attr.key"
              :item="attr"
            />
          </div>
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script lang="ts">
import { defineComponent, readonly } from 'vue'
import { Calendar } from 'v-calendar';
import CalendarItem from './CalendarItem.vue';

export default defineComponent({
  components: {
    Calendar,
    CalendarItem
  },

  props: {
    attributes: {
      type: Array,
      default: () => ([])
    },
  },

  setup () {
    const masks = readonly({
      weekdays: 'WWW',
    })
    
    return {
      masks
    }
  }
})
</script>

<style>
.mc-calendar.vc-container {
  width: 100%;
}
.mc-calendar.vc-container .vc-day.weekday-1 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
}
.mc-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom-width: 1px;
}
.mc-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom-width: 1px;
}
.mc-calendar.vc-container .vc-day:not(.on-right) {
  border-right-width: 1px;
}
.mc-calendar.vc-container .vc-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.mc-calendar.vc-container .vc-weeks {
  padding: 0px;
}
.mc-calendar.vc-container .vc-weekday {
  border-top-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0px;
  padding-right: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.mc-calendar.vc-container .vc-day {
  padding-top: 0px;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-align: left;
  height: 8rem;
  min-width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.mc-calendar.vc-container .vc-day-dots {
  margin-bottom: 0.25rem;
}
</style>
